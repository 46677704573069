<template>
  <div style="width: 100%; text-align: center; margin-top: 30px">
    <br />
    {{ loginInfo }}
    <br />
  </div>
</template>

<script>
import { UserIdLogin } from "@/api/index.js";
import { setSessionStr, setSessionObj } from "@/utils/session.js";

export default {
  data() {
    return {
      loginInfo: this.$t("lang.loading"),
      defaultRouter: ""
      //serverUrl: "",
    };
  },
  created() {
    localStorage.setItem("client", "xunjianapp");
    document.title = this.$t("lang.apptitle");
  },
  mounted() {
    /*
    try {
      window.serviceDomainCallback = (e) => {
        this.infoCallback1(e);
      };
      this.loginInfo = this.$t("lang.loading");
      window.getServiceDomain.postMessage("");
    } catch (e) {
      this.serverUrl = e;
    }
    */

    try {
      window.infoCallback = (e) => {
        this.infoCallback(e);
      };
      this.loginInfo = this.$t("lang.loading");
      window.appUserInfo.postMessage("");
    } catch (e) {
      this.loginInfo = e;
    }

    //this.infoCallback("{\"userId\":\"2\",\"currRouter\":1}");
  },
  methods: {
    /*
    infoCallback1(serverurl) {
      if (serverurl.charAt(serverurl.length - 1) != "/")
        serverurl = serverurl + "/";
      setSessionStr("ServerUrl", serverurl);
    },
    */

    infoCallback(strUserInfo) {
      //this.loginInfo=strUserInfo;
      let _strUserInfo = "";
      try {
        _strUserInfo = strUserInfo.replace("\n","＼n").replace("\r","＼r").replace("\t","＼t").replace("\b","＼b").replace("\f","＼f").replace("\v","＼v");
        let objUser = JSON.parse(_strUserInfo);//.data;
        this.defaultRouter = (objUser.currRouter == undefined ? "" : objUser.currRouter);
        this.loginInfo = "正在加载...";//"原始数据：" + strUserInfo;
        this.login(objUser.userId);
      }
      catch (e) {
        this.loginInfo = "程序错误：" + e;
      }
    },

    login(_userid) {
      UserIdLogin(
        {
          userId: _userid,
        },
        (ret) => {
          if (ret) {
            if (ret.code == 0) {
              this.$store.commit("setToken", ret.data.token);
              this.$store.commit("setUser", ret.data.user);
              this.$store.commit("setCompanys", ret.data.companys);
              this.$store.commit("setDepts", this.$Tools.sortBykey(ret.data.depts, "id"));
              this.$store.commit("setGroup", ret.data.group);
              this.$store.commit("setInspector", ret.data.inspector);
              this.$store.commit("setPermission", ret.data.permission);
              this.$store.commit("setPlace", ret.data.place);
              this.$store.commit("setRoutes", this.$Tools.sortBykey(ret.data.routes, "id"));
              this.$store.commit("setDevices", ret.data.devices==undefined?[]:ret.data.devices);

              //初始化查询条件
              let date1 = this.$Tools.FormatDate2(new Date()) + " 00:00:01";
              let date2 = this.$Tools.FormatDate2(new Date()) + " 23:59:59";
              setSessionStr("Query_Date", this.$t("lang.today"));
              setSessionStr("Query_Date_1", date1);
              setSessionStr("Query_Date_2", date2);

              let com_name = "";
              let com_id = "0";
              let dept_name = "";
              let dept_id = "0";
              let router_name="";

              let flag=false;
              //有默认值
              if (this.defaultRouter != "" && this.defaultRouter != "0") {
                let lstData = ret.data.routes.filter((d) => { return d.id == this.defaultRouter; });
                if(lstData.length>0){
                  router_name=lstData[0].name;
                  dept_id=lstData[0].deptId;
                  lstData=ret.data.depts.filter((d) => { return d.id == dept_id; });
                  if(lstData.length>0){
                    dept_name=lstData[0].name;
                    com_id=lstData[0].companyId;
                    lstData=ret.data.companys.filter((d) => { return d.id == com_id; });
                    if(lstData.length>0){
                      com_name=lstData[0].name;
                      flag=true;
                    }
                  }
                }
              }
              //无默认值
              if (!flag) {
                com_name = ret.data.companys.length > 0 ? ret.data.companys[0].name : "";
                com_id = ret.data.companys.length > 0 ? ret.data.companys[0].id : "0";
                if (ret.data.depts.length > 0) {
                  let lstData = ret.data.depts.filter((d) => { return d.companyId == com_id; });
                  dept_name = lstData.length > 0 ? lstData[0].name : "";
                  dept_id = lstData.length > 0 ? lstData[0].id : "0";
                }
              }

              setSessionStr("Query_Company", com_name);
              setSessionStr("Query_Company_id", com_id);
              setSessionStr("Query_Dept", dept_name);
              setSessionStr("Query_Dept_id", dept_id);
              setSessionStr("Query_Line", router_name);
              setSessionStr("Query_Line_id", this.defaultRouter);
              
              setSessionStr("Query_RepairType", "-1");
              setSessionStr("Query_Record_miss", "0");
              setSessionStr("Query_Record_local", "1");

              //参数
              let par = {
                mCompany: com_id,
                mDept: dept_id,
                mDate1: date1,
                mDate2: date2,
                mLine: this.defaultRouter,
                mAddr: "",
                mGroup: "",
                mUser: "",
                mDevice: "",
                mRecord: "-1",
                mRecord_miss: "0",
                mRecord_local: "1",
                mState: "",
                mOrder: "desc",
                mTotalType: "4",
                mRepairType: "-1",
                mKeyword: "",
                pagesize: 20,
              };

              //console.log(par);
              //console.log(com_id+","+com_name);
              //console.log(dept_id+","+dept_name);
              //console.log(this.defaultRouter+","+router_name);
              //this.loginInfo += (com_id+","+com_name+"|"+dept_id+","+dept_name+"|"+this.defaultRouter+","+router_name);

              setSessionObj("Query_Params", par);

              this.$store.commit("setIsXunjianApp", true);
              this.$router.replace({ path: "/query/appindex" });

            } else if (ret.code != 9) this.$Toast(ret.message);
          } else this.$Toast(this.$t("lang.login_fail"));
        }
      );
    }
  },
};

</script>
